<template>
	<div>
		<title-bar :title-stack="titleStack"/>

		<section class="section is-main-section">
			<card-component title="" icon="">
				<b-field label="期間指定" horizontal class="" >
					<div class="month_select">
						<b-button type="is-success" icon-pack="fas" icon-right="chevron-left" :disabled="isPreviousMonthButtonDisable" @click="onMonthPreviousClick"/>

						<b-select v-model="year">
							<option v-for="row in years" :key="row" :value="row">
								{{ row }}
							</option>
						</b-select>

						<span>/</span>

						<b-select v-model="month">
							<option v-for="n of 12" :key="n" :value="$utils.zeroPadding(n, 2)">
								{{ n }}
							</option>
						</b-select>

						<b-button type="is-success" icon-pack="fas" icon-right="chevron-right" :disabled="isNextMonthButtonDisable" @click="onMonthNextClick"/>
					</div>
				</b-field>

				<b-field label="グループ" horizontal>
					<b-select v-model="groupId" @input="onGroupChange">
						<option v-for="(row, index) in groups" :key="index" :value="row.id">
							{{ row.name }}
						</option>
					</b-select>
				</b-field>

				<b-field label="加盟店" horizontal>
					<b-select v-model="storeId">
						<option value="">すべて</option>
						<option v-for="(row, index) in stores" :key="index" :value="row.id">
							{{ row.name }}
						</option>
					</b-select>
				</b-field>

				<b-field horizontal label="" class="">
					<b-field grouped>
						<b-button type="is-primary" :loading="isPdfLoading" @click="download">ダウンロード</b-button>
					</b-field>
				</b-field>
			</card-component>
		</section>
	</div>

</template>

<style lang="scss" scoped>
@import '../scss/_mixins.scss';
.conditions {
	padding: 15px;
	padding-bottom: 25px;
}
.month_select {
	@include flex;

	&-input {
		width: 180px !important;
	}

	span {
		margin: 0 10px;
	}

	.button {
		width: 40px ;
		&:first-child {
			margin-right: 10px;
		}
		&:last-child {
			margin-left: 10px;
		}
	}
}
</style>

<script>
import Notification from '@/components/Notification'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import RefreshButton from '@/components/RefreshButton'
import CardToolbar from '@/components/CardToolbar'
import TableEmpty from '@/components/TableEmpty'
import PageInfo from '@/components/PageInfo'
import moment from 'moment'
import DatePicker from '@/components/DatePicker'
import RadioPicker from '@/components/RadioPicker'

export default {
	name: 'Bill',
	components: {
		TitleBar,
		CardComponent,
	},
	data () {
		return {
			isLoading: false,
			isPdfLoading: false,
			year: moment().format('YYYY'),
			month: moment().format('MM'),
			groupId: '',
			storeId: '',
			groups: [],
			stores: [],
			years: [],
		}
	},
	computed: {
		titleStack () {
			return [
				this.$route.meta.title,
			]
		},
		isPreviousMonthButtonDisable: function() {
			return this.years[0] > this.previousYear;
		},
		isNextMonthButtonDisable: function() {
			return this.years[this.years.length - 1] < this.nextYear;
		},
		currentMonth: function() {
			return `${this.year}-${this.month}-01`;
		},
		previousYear: function() {
			return moment(this.currentMonth).add(-1, 'month').startOf('month').format('YYYY');
		},
		nextYear: function() {
			return moment(this.currentMonth).add(+1, 'month').startOf('month').format('YYYY');
		},
		previousMonth: function() {
			return moment(this.currentMonth).add(-1, 'month').startOf('month').format('MM');
		},
		nextMonth: function() {
			return moment(this.currentMonth).add(+1, 'month').startOf('month').format('MM');
		},
	},
	created () {
		for (let i = 2021; i <= moment().format('YYYY'); i++) {
			this.years.push(i);
		}
	},
	mounted () {
		this.init();
	},
	methods: {
		onMonthPreviousClick: function() {
			this.year = this.previousYear;
			this.month = this.previousMonth;
		},
		onMonthNextClick: function() {
			this.year = this.nextYear;
			this.month = this.nextMonth;
		},
		onGroupChange: async function() {
			if (this.groupId === '') {
				this.storeId = '';
				this.stores = [];
			} else {
				let ep = 'sales/bill-stores/' + this.groupId
				this.$utils.log(ep);

				try {
					let res = await this.$api.get(ep, null, true);
					this.$utils.log(res);
					this.storeId = '';
					this.stores = res.data.stores;
				} catch (error) {
					this.$utils.log(error.response);
					this.$utils.toastError(error.response.data.message);
				} finally {
				}
			}
		},
		init: function() {
			this.getData();
		},
		getData: async function() {
			this.isLoading = true;

			let ep = 'sales/bill-screen';
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.groups = res.data.groups;
				this.stores = res.data.stores;
				this.groupId = this.groups[0].id;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
		download: async function() {
			this.isPdfLoading = true;

			let ep = 'sales/download-bill';
			this.$utils.log(ep);

			let data = {
				"current_month": this.currentMonth,
				"group_id": this.groupId,
				"store_id": this.storeId,
			};
			this.$utils.log(data);

			try {
				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);

				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(this.$utils.toBlob(res.data.body, res.data.mime_type))
				link.download = res.data.file_name;
				link.click()
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isPdfLoading = false;
			}
		},
	},
}
</script>
