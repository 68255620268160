<template>
	<div>
		<title-bar :title-stack="titleStack"/>

		<section class="section is-main-section">
			<card-component title="基本情報" icon="">
				<b-field label="タイトル"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'title') }"
					:message="errors.title">
					<b-input type="text" v-model="title" />
				</b-field>

				<b-field label="本文"
					horizontal>
					<b-input type="textarea" v-model="body" />
				</b-field>

				<b-field label="画像" horizontal>
					<div>
						<img class="preview" :src="imageBase" v-show="!image" :style="{ 'max-width': previewW + 'px' }">
						<image-picker class="mb-10" :previewUrl="image" :isLoading="isImageLoading" :previewW="previewW" @file-change="onImageChange" @delete="onImageDelete"></image-picker>
					</div>
				</b-field>

				<b-field label="表示先"
					horizontal
					custom-class="require"
					>
					<radio-picker :options="{ 1: 'すべての加盟店', 2: '個別選択' }" v-model="displayTargetId"></radio-picker>
				</b-field>

				<transition name="slide">
					<b-field v-show="displayTargetId === '2'" class="store-select-field" label="" horizontal>
						<div class="store-select-wrap">
							<div v-for="(row, index) in stores" :key="index">
								<b-checkbox v-model="informationStores"
									:native-value="row.id">
									{{ row.name }}
								</b-checkbox>
							</div>
						</div>
					</b-field>
				</transition>

				<b-field label="表示開始日時" horizontal>
					<date-picker :dateProvided="startDate" :hasError="$utils.hasError(errors, 'startDate')" @input="onStartDateInput"></date-picker>
				</b-field>

				<b-field label=""
					horizontal
					:type="{ 'is-danger': $utils.hasError(errors, 'startDate') }"
					:message="errors.startDate">
					<time-select :timeProvided="startTime" :hasEmptyOption="true" @change="onStartTimeChange"></time-select>
				</b-field>

				<b-field label="表示終了日時" horizontal>
					<date-picker :dateProvided="endDate" :hasError="$utils.hasError(errors, 'endDate')" @input="onEndDateInput"></date-picker>
				</b-field>

				<b-field label=""
					horizontal
					:type="{ 'is-danger': $utils.hasError(errors, 'endDate') }"
					:message="errors.endDate">
					<time-select :timeProvided="endTime" :hasEmptyOption="true" @change="onEndTimeChange"></time-select>
				</b-field>

				<b-field label="種別"
					class="mt-20"
					horizontal
					custom-class="require"
					>
					<radio-picker :options="informationStatuses" v-model="informationStatusId"></radio-picker>
				</b-field>

				<b-field horizontal class="form-buttons">
					<b-field grouped>
						<div class="control">
							<b-button @click="$router.go(-1)">戻る</b-button>
						</div>
						<div class="control">
							<b-button type="is-primary" :loading="isLoading" @click="onSubmitClick">{{ !id ? '登録' : '更新' }}</b-button>
						</div>
					</b-field>
				</b-field>
			</card-component>
		</section>
	</div>
</template>

<style lang="scss" scoped>
.store-select-field {
	height: 250px;
	overflow: hidden;
}
.store-select-wrap {
	height: 100%;
	overflow: scroll;
	border: 1px solid #dbdbdb;
	border-radius: 4px;
	padding: 10px;
}
.preview {
	height: auto;
}
</style>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import RadioPicker from '@/components/RadioPicker'
import ImagePicker from '@/components/ImagePicker'
import DatePicker from '@/components/DatePicker'
import TimeSelect from '@/components/TimeSelect'

export default {
	name: 'InformationEdit',
	components: {
		RadioPicker,
		CardComponent,
		TitleBar,
		ImagePicker,
		DatePicker,
		TimeSelect,
	},
	data () {
		return {
			id: '',
			title: '',
			body: '',
			image: '',
			imageBase: '',
			startDate: '',
			startTime: '',
			endDate: '',
			endTime: '',
			informationStatusId: null,
			informationStores: [],
			errors: {
				title: '',
				startDate: '',
				endDate: '',
			},
			informationStatuses: null,
			isLoading: false,
			isImageLoading: false,
			previewW: 200,
			displayTargetId: '',
			stores: [],
		}
	},
	computed: {
		titleStack () {
			return [
				this.$route.meta.title,
			]
		},
		startDateTime: function() {
			if (this.startDate === '' && this.startTime ==='') {
				return '';
			}
			return this.startDate + ' ' + this.startTime;
		},
		endDateTime: function() {
			if (this.endDate === '' && this.endTime ==='') {
				return '';
			}
			return this.endDate + ' ' + this.endTime;
		},
	},
	mounted() {
		this.init();
	},
	methods: {
		onEndTimeChange: function(time) {
			this.endTime = time;
		},
		onEndDateInput: function(date) {
			this.endDate = date;
		},
		onStartTimeChange: function(time) {
			this.startTime = time;
		},
		onStartDateInput: function(date) {
			this.startDate = date;
		},
		onImageDelete: function() {
			this.image = '';
		},
		onImageChange: function(file) {
			this.uploadImage(file);
		},
		uploadImage: async function(file, key) {
			this.isImageLoading = true;

			let data = {
				"image": file,
			};
			let ep = 'informations/image';

			try {
				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);
				this.image = res.data.url;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isImageLoading = false;
			}
		},
		init: function() {
			this.getData();
		},
		getData: async function() {
			let loader = this.$buefy.loading.open();
			let params = this.$route.params.informationId ? '/' + this.$route.params.informationId : '';
			let ep = 'informations/screen' + params;
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.id = res.data.id;
				this.title = res.data.title;
				this.body = res.data.body;
				this.image = res.data.image;
				this.imageBase = res.data.image_base;
				this.startDate = res.data.start_date;
				this.endDate = res.data.end_date;
				this.startTime = res.data.start_time;
				this.endTime = res.data.end_time;
				this.informationStatusId = res.data.information_status_id;
				this.informationStatuses = res.data.information_statuses;
				this.informationStores = res.data.information_stores;
				this.stores = res.data.stores;

				this.displayTargetId = this.informationStores.length === 0 ? '1' : '2';
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				loader.close();
			}
		},
		onSubmitClick: async function() {
			for (let key in this.errors) {
				this.errors[key] = '';
			}

			this.isLoading = true;
			let data = {
				"id": this.id,
				"title": this.title,
				"body": this.body,
				"image": this.image,
				"start_date": this.startDateTime,
				"end_date": this.endDateTime,
				"information_status_id": this.informationStatusId,
			};

			let is = '';
			if (this.displayTargetId === '2' && this.informationStores.length > 0) is = this.informationStores;
			data.information_stores = is;

			this.$utils.log(data);

			try {
				let ep = 'informations';

				if (!this.id) {
					await this.$api.post(ep, data, true);
				} else {
					await this.$api.put(ep + '/' + this.id, data, true);
					this.$utils.toastSuccess('データを更新しました');
				}
				this.$router.push({ name: 'information' });
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
				if (error.response.status === 400) {
					let details = error.response.data.details;
					for (let key in details) {
						this.errors[this.$utils.toCamelCase(key)] = details[key];
					}
				}
			} finally {
				this.isLoading = false;
			}
		},
	}
}
</script>
