<template>
	<div class="horizontal-center">
		<b-select v-model="hour" @input="onChange">
			<option v-if="hasEmptyOption" value="">未選択</option>
			<option value="00">0</option>
			<option
				v-for="n in 23"
				:value="$utils.zeroPadding(n, 2)"
				:key="n">
				{{ n }}
			</option>
		</b-select>

		<div class="text">時</div>

		<b-select class="ml-20" v-model="minute" @input="onChange">
			<option v-if="hasEmptyOption" value="">未選択</option>
			<option value="00">0</option>
			<option
				v-for="n in 59"
				:value="$utils.zeroPadding(n, 2)"
				:key="n">
				{{ n }}
			</option>
		</b-select>

		<div class="text">分</div>
	</div>
</template>

<style lang="scss" scoped>
.text {
	margin-left: 5px;
}
</style>

<script>

export default {
	name: 'TimeSelect',
	props: {
		timeProvided: {
			type: String,
			default: ''
		},
		hasEmptyOption: {
			type: Boolean,
			default: false,
		},
	},
	components: {
	},
	data: function() {
		return {
			hour: null,
			minute: null,
		}
	},
	mounted() {
		this.setTime();
	},
	watch: {
		timeProvided: function() {
			this.setTime();
		},
	},
	methods: {
		setTime: function() {
			if (this.timeProvided !== '') {
				let times = this.timeProvided.split(':');
				this.hour = times[0];
				this.minute = times[1];
			} else {
				this.hour = '';
				this.minute = '';
			}
		},
		onChange: function() {
			let time = '';
			if (!(this.hour === '' && this.minute === '')) {
				time = `${this.hour}:${this.minute}:00`;
			}
			this.$emit('change', time);
		},
	},
}
</script>
