import { render, staticRenderFns } from "./InformationEdit.vue?vue&type=template&id=bc195dda&scoped=true&"
import script from "./InformationEdit.vue?vue&type=script&lang=js&"
export * from "./InformationEdit.vue?vue&type=script&lang=js&"
import style0 from "./InformationEdit.vue?vue&type=style&index=0&id=bc195dda&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc195dda",
  null
  
)

export default component.exports