<template>
	<div>
		<title-bar :title-stack="titleStack"/>

		<section class="section is-main-section">
			<card-component class="has-table has-mobile-sort-spaced" :hasButtonSlot="true" title="一覧" icon="format-list-bulleted">
				<b-button slot="button" type="is-success is-small" @click="onExcelClick" :loading="isExcelLoading">Excelダウンロード</b-button>

				<div class="conditions">
					<b-field label="期間指定" horizontal class="" >
						<radio-picker :options="periods" v-model="periodId" @input="onPeriodChange"></radio-picker>
					</b-field>

					<b-field v-show="periodId == 1" label="" horizontal class="" >
						<div class="month_select">
							<b-button type="is-success" icon-pack="fas" icon-right="chevron-left" :disabled="isPreviousMonthButtonDisable" @click="onMonthPreviousClick"/>

							<b-select v-model="year" @input="onYearChange">
								<option v-for="row in years" :key="row" :value="row">
									{{ row }}
								</option>
							</b-select>

							<span>/</span>

							<b-select v-model="month" @input="onMonthChange">
								<option v-for="n of 12" :key="n" :value="$utils.zeroPadding(n, 2)">
									{{ n }}
								</option>
							</b-select>

							<b-button type="is-success" icon-pack="fas" icon-right="chevron-right" :disabled="isNextMonthButtonDisable" @click="onMonthNextClick"/>
						</div>
					</b-field>

					<b-field v-show="periodId == 2" label="" horizontal class="" >
						<div class="date_select">
							<date-picker class="date_select-input" :allowInput="false" :dateProvided="startDate" @input="onStartDateInput"></date-picker>
							<span> ～ </span>
							<date-picker class="date_select-input" :allowInput="false" :dateProvided="endDate" @input="onEndDateInput"></date-picker>
						</div>
					</b-field>

					<b-field label="グループ" horizontal>
						<b-select v-model="groupId" @input="onGroupChange">
							<option value="">すべて</option>
							<option v-for="(row, index) in groups" :key="index" :value="row.id">
								{{ row.name }}
							</option>
						</b-select>
					</b-field>

					<b-field label="加盟店" horizontal>
						<b-select v-model="storeId" @input="onStoreChange">
							<option value="">すべて</option>
							<option v-for="(row, index) in stores" :key="index" :value="row.id">
								{{ row.name }}
							</option>
						</b-select>
					</b-field>

					<b-field label="ブランド" horizontal>
						<b-select v-model="brandId" @input="onBrandChange">
							<option value="">すべて</option>
							<option v-for="(row, index) in brands" :key="index" :value="row.id">
								{{ row.name }}
							</option>
						</b-select>
					</b-field>

					<b-field label="" horizontal>
						<div>※売上にマイナス伝票分を反映する場合は、ブランドは「すべて」にしてください</div>
					</b-field>
				</div>

				<b-table
					:loading="isLoading"
					:paginated="false"
					:per-page="$consts.TABLE_PER_PAGE"
					:striped="true"
					:hoverable="true"
					default-sort=""
					:data="list"
					>

					<b-table-column label="確定日" field="date" sortable v-slot="props" subheading="合計:">
						{{ props.row.date }}
					</b-table-column>
					<b-table-column label="売上" field="sales" sortable numeric v-slot="props" :subheading="subTotal.toLocaleString() + '円'">
						{{ props.row.sales.toLocaleString() }}円
					</b-table-column>
					<b-table-column label="消費税(8%)" field="tax_class2" numeric v-slot="props" :subheading="taxClass2Total.toLocaleString() + '円'">
						{{ props.row.tax_class2.toLocaleString() }}円
					</b-table-column>
					<b-table-column label="税(10%)" field="tax_class1" numeric v-slot="props" :subheading="taxClass1Total.toLocaleString() + '円'">
						{{ props.row.tax_class1.toLocaleString() }}円
					</b-table-column>
					<b-table-column label="計" field="total" sortable numeric v-slot="props" :subheading="grandTotal.toLocaleString() + '円'">
						{{ props.row.total.toLocaleString() }}円
					</b-table-column>

					<section class="section" slot="empty">
						<table-empty :loading="isLoading"></table-empty>
					</section>
				</b-table>
			</card-component>
		</section>
	</div>

</template>

<style lang="scss" scoped>
@import '../scss/_mixins.scss';
.conditions {
	padding: 15px;
	padding-bottom: 25px;
}
.month_select {
	@include flex;

	&-input {
		width: 180px !important;
	}

	span {
		margin: 0 10px;
	}

	.button {
		width: 40px ;
		&:first-child {
			margin-right: 10px;
		}
		&:last-child {
			margin-left: 10px;
		}
	}
}

.date_select {
	@include flex($wrap:wrap);

	&-input {
		width: 180px !important;
	}

	span {
		margin: 0 10px;
	}
}

::v-deep .field-label {
	text-align: left;
}
</style>

<script>
import Notification from '@/components/Notification'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import RefreshButton from '@/components/RefreshButton'
import CardToolbar from '@/components/CardToolbar'
import TableEmpty from '@/components/TableEmpty'
import PageInfo from '@/components/PageInfo'
import moment from 'moment'
import DatePicker from '@/components/DatePicker'
import RadioPicker from '@/components/RadioPicker'

export default {
	name: 'Sales',
	components: {
		TitleBar,
		CardComponent,
		TableEmpty,
		DatePicker,
		RadioPicker,
	},
	data () {
		return {
			isLoading: false,
			list: [],
			targetDate: '',
			isExcelLoading: false,
			periods: { '1': '月間', '2': '期間指定' },
			periodId: '1',
			startDate: moment().format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
			year: moment().format('YYYY'),
			month: moment().format('MM'),
			groupId: '',
			storeId: '',
			brandId: '',
			categoryId: '',
			groups: [],
			stores: [],
			brands: [],
			years: [],
			subTotal: 0,
			taxClass1Total: 0,
			taxClass2Total: 0,
			grandTotal: 0,
		}
	},
	computed: {
		titleStack () {
			return [
				this.$route.meta.title,
			]
		},
		isPreviousMonthButtonDisable: function() {
			return this.years[0] > this.previousYear;
		},
		isNextMonthButtonDisable: function() {
			return this.years[this.years.length - 1] < this.nextYear;
		},
		currentMonth: function() {
			return `${this.year}-${this.month}-01`;
		},
		previousYear: function() {
			return moment(this.currentMonth).add(-1, 'month').startOf('month').format('YYYY');
		},
		nextYear: function() {
			return moment(this.currentMonth).add(+1, 'month').startOf('month').format('YYYY');
		},
		previousMonth: function() {
			return moment(this.currentMonth).add(-1, 'month').startOf('month').format('MM');
		},
		nextMonth: function() {
			return moment(this.currentMonth).add(+1, 'month').startOf('month').format('MM');
		},
	},
	created () {
		for (let i = 2021; i <= moment().format('YYYY'); i++) {
			this.years.push(i);
		}
	},
	mounted () {
		this.init();
	},
	methods: {
		onMonthPreviousClick: function() {
			this.year = this.previousYear;
			this.month = this.previousMonth;
			this.onChangeCondition();
		},
		onMonthNextClick: function() {
			this.year = this.nextYear;
			this.month = this.nextMonth;
			this.onChangeCondition();
		},
		onYearChange: function() {
			this.onChangeCondition();
		},
		onMonthChange: function() {
			this.onChangeCondition();
		},
		onGroupChange: async function() {
			if (this.groupId === '') {
				this.storeId = '';
				this.stores = [];
				this.onChangeCondition();
			} else {
				let ep = 'sales/stores/' + this.groupId
				this.$utils.log(ep);

				try {
					let res = await this.$api.get(ep, null, true);
					this.$utils.log(res);
					this.storeId = '';
					this.stores = res.data.stores;
					this.onChangeCondition();
				} catch (error) {
					this.$utils.log(error.response);
					this.$utils.toastError(error.response.data.message);
				} finally {
				}
			}
		},
		onPeriodChange: async function() {
			this.onChangeCondition();
		},
		onStoreChange: async function() {
			this.onChangeCondition();
		},
		onBrandChange: async function() {
			this.onChangeCondition();
		},
		onStartDateInput: function(date) {
			this.startDate = date;
			this.onChangeCondition();
		},
		onEndDateInput: function(date) {
			this.endDate = date;
			this.onChangeCondition();
		},
		onChangeCondition: function() {
			this.getData(false);
		},
		init: function() {
			this.getData();
		},
		getData: async function(isSetOption = true) {
			this.isLoading = true;

			let params = new URLSearchParams();
			params.append('period_id', this.periodId);
			params.append('start', this.startDate);
			params.append('end', this.endDate);
			params.append('current_month', this.currentMonth);
			params.append('group_id', this.groupId);
			params.append('store_id', this.storeId);
			params.append('brand_id', this.brandId);
			this.$utils.log(params.toString());

			let ep = 'sales?' + params.toString();
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.list = res.data.list;
				this.subTotal = res.data.sub_total;
				this.taxClass1Total = res.data.tax_class1_total;
				this.taxClass2Total = res.data.tax_class2_total;
				this.grandTotal = res.data.grand_total;
				if (isSetOption) {
					this.groups = res.data.groups;
					this.brands = res.data.brands;
				}
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
		onExcelClick: async function() {
			this.isExcelLoading = true;

			let ep = 'sales/excel';
			this.$utils.log(ep);

			let data = {
				"period_id": this.periodId,
				"start": this.startDate,
				"end": this.endDate,
				"current_month": this.currentMonth,
				"group_id": this.groupId,
				"store_id": this.storeId,
				"brand_id": this.brandId,
			};
			this.$utils.log(data);

			try {
				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);

				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(this.$utils.toBlob(res.data.body, res.data.mime_type))
				link.download = res.data.file_name;
				link.click()
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isExcelLoading = false;
			}
		},
	},
}
</script>
